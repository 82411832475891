// const config = {
//   defaultLanguage: 'it',
//   graphQLUri: 'http://localhost:4000/graphql',
//   bridgeImageUri: 'http://localhost:4000/cameraViewImages/bridge',
// };

// const config = {
//   defaultLanguage: 'it',
//   graphQLUri: 'https://s-graphql-genialcam.geekcups.com/graphql',
//   bridgeImageUri: 'https://s-graphql-genialcam.geekcups.com/cameraViewImages/bridge',
// };

const config = {
  defaultLanguage: 'it',
  graphQLUri: 'https://backend.genialcam.com/graphql',
  bridgeImageUri: 'https://backend.genialcam.com/cameraViewImages/bridge',
};

export default config;
