/* eslint-disable max-len */
import React from 'react';
import {
  Row,
  Col,
  Button,
  Empty,
  Space,
  PageHeader,
  Table,
} from 'antd';
import {
  PlusOutlined,
  EyeOutlined,
  UserOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { CAMERA_VIEWS_QUERY } from '../queries';
import GraphQLErrorAlert from '../../commons/components/GraphQLErrorAlert';
import Filter from '../components/Filter';
import Loader from '../../commons/components/Loader';

const pageSize = 20;

const ListPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [filter, setFilter] = React.useState({});

  const {
    data, error, loading, fetchMore,
  } = useQuery(CAMERA_VIEWS_QUERY, {
    variables: {
      pageSize,
      filter: {
        ...filter,
      },
    },
    fetchPolicy: 'network-only',
  });

  const handleFilterChange = React.useCallback((newFilter) => {
    setFilter(newFilter);
  }, [setFilter]);

  const queryResult = React.useMemo(() => {
    if (!loading && !error) {
      const { viewer } = data;
      const { cameraViews: cameraViewConnection } = viewer;
      const { pageInfo, edges } = cameraViewConnection;
      return {
        cameraViews: edges.map((e) => e.node),
        pageInfo,
      };
    }
    return {
      cameraViews: [],
      pageInfo: null,
    };
  }, [data, error, loading]);

  const handleViewClick = React.useCallback((cameraView) => {
    navigate(`/cameraViews/${cameraView.id}`);
  }, [navigate]);

  const handleEditClick = React.useCallback((cameraView) => {
    navigate(`/cameraViews/edit/${cameraView.id}`);
  }, [navigate]);

  const handlePermissionClick = React.useCallback((cameraView) => {
    navigate(`/cameraViewViewingPermissions/?cameraViewId=${cameraView.id}&cameraViewName=${cameraView.name}`);
  }, [navigate]);

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        afterCursor: queryResult.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const { edges: oldEdges } = previousResult.viewer.cameraViews;
        const { pageInfo: newPageInfo, edges: newEdges } = fetchMoreResult.viewer.cameraViews;
        return newEdges.length
          ? {
            ...previousResult,
            viewer: {
              ...previousResult.viewer,
              cameraViews: {
                ...previousResult.viewer.camera.cameraViews,
                edges: [...oldEdges, ...newEdges],
                pageInfo: newPageInfo,
              },
            },
          }
          : previousResult;
      },
    });
  };

  const renderError = () => {
    if (error) {
      return (
        <Row gutter={16}>
          <Col flex={1}>
            <GraphQLErrorAlert error={error} />
          </Col>
        </Row>
      );
    }
    return null;
  };

  const renderLoadMore = () => {
    if (queryResult.pageInfo && queryResult.pageInfo.hasNextPage) {
      return (
        <Row type="flex" justify="center" align="middle">
          <Col>
            <Button loading={loading} type="primary" onClick={handleLoadMore}>
              {t('common.loadMore')}
            </Button>
          </Col>
        </Row>
      );
    }

    return null;
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Row justify="center" align="middle" style={{ height: '100%' }}>
          <Loader />
        </Row>
      );
    }
    if (queryResult.cameraViews.length === 0) {
      return (
        <Empty
          description={t('common.noData')}
        />
      );
    }

    const { cameraViews } = queryResult;

    const columns = [
      {
        title: t('cameraViews.pages.list.table.name'),
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => (
          <Button type="text" onClick={() => handleViewClick(record)}>{text}</Button>
        ),
        width: '100%',
      },
      {
        title: t('cameraViews.pages.list.table.actions'),
        key: 'action',
        render: (text, record) => (
          <Space size="middle">
            <Button type="primary" shape="circle" icon={<EyeOutlined />} onClick={() => handleViewClick(record)} />
            {record.iAmOwner && <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={() => handleEditClick(record)} />}
            {record.iAmOwner && <Button type="primary" shape="circle" icon={<UserOutlined />} onClick={() => handlePermissionClick(record)} />}
          </Space>
        ),
      },
    ];

    return (
      <Row gutter={[16, 16]}>
        <Table
          columns={columns}
          dataSource={cameraViews}
          style={{ width: '100%' }}
          rowKey="id"
        />
      </Row>
    );
  };

  const renderFilter = () => (
    <Row>
      <Col flex={1}>
        <Filter
          filter={filter}
          onFilterChange={handleFilterChange}
        />
      </Col>
    </Row>
  );

  const renderExtra = () => (
    <Button type="action" icon={<PlusOutlined />} onClick={() => navigate('/cameraViews/new')}>
      {t('cameraViews.pages.list.createButtonText')}
    </Button>
  );

  return (
    <>
      <Row gutter={16}>
        <Col flex={1}>
          <PageHeader
            title={t('cameraViews.pages.list.title')}
            extra={renderExtra()}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col flex={1}>
          <Space style={{ width: '100%' }} direction="vertical" size="large">
            {renderError()}
            {renderFilter()}
            {renderContent()}
            {renderLoadMore()}
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default ListPage;
