import React from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Modal,
  Alert,
  Button,
  Select,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import CAMERA_TYPES from '../enums/cameraTypes';
import BoshFixedForm from './cameraModelModals/BoshFixedForm';
import BoshEncoderForm from './cameraModelModals/BoshEncoderForm';
import HikvisionDVRForm from './cameraModelModals/HikvisionDVRForm';
import DahuaDVRNVRForm from './cameraModelModals/DahuaDVRNVR';

const SourceImageConfigInput = (props) => {
  const {
    value,
    onChange,
  } = props;

  const { t } = useTranslation();

  const [showModal, setShowModal] = React.useState(false);
  const [cameraType, setCameraType] = React.useState(null);

  const realValue = React.useMemo(() => {
    if (value && value.trim() !== '') {
      return JSON.parse(value);
    }
    return null;
  }, [value]);

  React.useEffect(() => {
    if (realValue) {
      setCameraType(realValue.type);
    }
    else {
      setCameraType(CAMERA_TYPES.BOSH_FIXED);
    }
  }, [realValue]);

  const cameraTypeOptions = React.useMemo(() => {
    const result = Object.keys(CAMERA_TYPES).map((ct) => (
      <Select.Option key={ct} value={ct}>
        {t(`cameraViews.components.sourceImageConfigInput.cameraTypes.${ct}`)}
      </Select.Option>
    ));
    return result;
  }, []);

  const handleModalSubmit = (data) => {
    setShowModal(false);
    onChange(JSON.stringify({
      ...data,
      type: cameraType,
    }));
  };

  const renderAlert = () => {
    if (realValue) {
      return (
        <Alert type="info" message={t(`cameraViews.components.sourceImageConfigInput.cameraTypes.${realValue.type}`)} />
      );
    }
    return (
      <Alert type="warning" message={t('cameraViews.components.sourceImageConfigInput.unSet')} />
    );
  };

  const renderSelect = () => (
    <>
      <Typography.Text>{t('cameraViews.components.sourceImageConfigInput.type')}</Typography.Text>
      <Select
        value={cameraType}
        onChange={(ct) => setCameraType(ct)}
        style={{ width: '100%' }}
      >
        {cameraTypeOptions}
      </Select>
    </>
  );

  const renderModal = () => {
    let form;
    if (cameraType === CAMERA_TYPES.BOSH_FIXED) {
      form = (
        <BoshFixedForm
          config={realValue}
          onSubmit={handleModalSubmit}
        />
      );
    }
    else if (cameraType === CAMERA_TYPES.BOSH_ENCODER) {
      form = (
        <BoshEncoderForm
          config={realValue}
          onSubmit={handleModalSubmit}
        />
      );
    }
    else if (cameraType === CAMERA_TYPES.HIKVISION_DVR) {
      form = (
        <HikvisionDVRForm
          config={realValue}
          onSubmit={handleModalSubmit}
        />
      );
    }
    else if (cameraType === CAMERA_TYPES.DAHUA_DVR_NVR) {
      form = (
        <DahuaDVRNVRForm
          config={realValue}
          onSubmit={handleModalSubmit}
        />
      );
    }

    return (
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={[]}
      >
        <Row style={{ marginTop: 24 }} gutter={16}>
          <Col flex={1}>
            {renderSelect()}
          </Col>
        </Row>
        <Row style={{ marginTop: 24 }} gutter={16}>
          <Col flex={1}>
            {form}
          </Col>
        </Row>
      </Modal>
    );
  };

  return (
    <>
      <Row gutter={16}>
        <Col flex={12}>
          {renderAlert()}
        </Col>
        <Col flex={12}>
          <Button onClick={() => setShowModal(true)}>{t('cameraViews.components.sourceImageConfigInput.config')}</Button>
        </Col>
      </Row>
      {renderModal()}
    </>
  );
};

const propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  value: null,
};

SourceImageConfigInput.propTypes = propTypes;
SourceImageConfigInput.defaultProps = defaultProps;

export default SourceImageConfigInput;
