import React from 'react';
import {
  Outlet,
  Link,
} from 'react-router-dom';
import {
  Button,
  Layout,
  Menu,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { LogoutOutlined } from '@ant-design/icons';
import { AuthenticationContext } from '../authentications/contexts/AuthenticationContext';

import logo from '../assets/images/logo.png';

const AuthenticatedLayout = () => {
  const { logout } = React.useContext(AuthenticationContext);
  const { t } = useTranslation();

  const renderMenu = () => {
    const result = [
      <Menu.Item key="cameraViews">
        <Link to="/">
          {t('menu.cameraViews')}
        </Link>
      </Menu.Item>,
    ];

    result.push((
      <Menu.Item key="cameraViewViewingPermissions">
        <Link to="/cameraViewViewingPermissions">
          {t('menu.cameraViewViewingPermissions')}
        </Link>
      </Menu.Item>
    ));

    result.push((
      <Menu.Item key="userProfile">
        <Link to="/profile">
          {t('menu.profile')}
        </Link>
      </Menu.Item>
    ));

    return result;
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout.Header>
        <img
          src={logo}
          alt="logo"
          style={{
            float: 'left',
            height: '100%',
            padding: 16,
          }}
        />
        <div style={{ float: 'right' }}>
          <Button
            shape="circle"
            icon={<LogoutOutlined />}
            ghost
            onClick={() => logout()}
          />
        </div>
        <Menu theme="dark" mode="horizontal">
          {renderMenu()}
        </Menu>
      </Layout.Header>
      <Layout.Content style={{ padding: 50, backgroundColor: '#DDDDDD' }}>
        <Outlet />
      </Layout.Content>
    </Layout>
  );
};

export default AuthenticatedLayout;
