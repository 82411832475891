import React from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';

import {
  Row,
  Col,
  Form,
  Input,
  Button,
} from 'antd';
import { useTranslation } from 'react-i18next';

const Filter = (props) => {
  const {
    filter,
    onFilterChange,
  } = props;

  const { t } = useTranslation();
  const [searchValue, setSearchValue] = React.useState(filter.search || '');

  React.useEffect(() => {
    setSearchValue(filter.search || '');
  }, [filter]);

  const debounced = useDebouncedCallback((value) => {
    onFilterChange({ search: value });
  }, 500);

  return (
    <Row gutter={16}>
      <Col flex={1}>
        <Form layout="vertical">
          <Form.Item
            label={t('cameraViews.components.filter.searchLabel')}
          >
            <Input
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                debounced(e.target.value);
              }}
              size="large"
              allowClear
            />
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

const propTypes = {
  filter: PropTypes.shape({
    search: PropTypes.string,
  }),
  onFilterChange: PropTypes.func.isRequired,
};

const defaultProps = {
  filter: null,
};

Filter.propTypes = propTypes;
Filter.defaultProps = defaultProps;

export default Filter;
