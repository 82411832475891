/* eslint-disable no-empty */
import React from 'react';

import {
  Row,
  Col,
  Card,
  Image,
  Space,
  Button,
  Alert,
} from 'antd';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import logo from '../../assets/images/logo.png';
import RegisterForm from '../components/RegisterForm';

import { AUTHENTICATION_REGISTER_MUTATION } from '../queries';

const RegisterPage = () => {
  const { t } = useTranslation();

  const [
    registerMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(AUTHENTICATION_REGISTER_MUTATION);

  const [completed, setCompleted] = React.useState(false);

  const navigate = useNavigate();

  const handleNavigation = React.useCallback((path) => {
    navigate(path);
  }, [navigate]);

  const handleSubmit = React.useCallback(async (data) => {
    try {
      await registerMutationAction({
        variables: {
          input: {
            displayName: data.displayName,
            email: data.email,
            password: data.password,
          },
        },
      });
      setCompleted(true);
    }
    catch (e) { }
  }, [registerMutationAction, setCompleted]);

  return (
    <Row type="flex" justify="center" align="middle" style={{ height: '100vh' }}>
      <Col xs={24} sm={8}>
        <Card>
          <Space direction="vertical">
            <Row type="flex" align="middle" justify="center">
              <Image src={logo} preview={false} />
            </Row>
            {!completed && (
              <RegisterForm
                loading={loading}
                serverErrors={error}
                onSubmit={handleSubmit}
              />
            )}
            {completed && (
              <Alert
                type="success"
                message={t('authentications.pages.register.successMessage')}
                description={t('authentications.pages.register.successDescription')}
              />
            )}
          </Space>
          <Row type="flex" align="middle" justify="center">
            <Button
              type="link"
              onClick={() => handleNavigation('/recoverPassword')}
            >
              {t('authentications.pages.register.recoverPassword')}
            </Button>
          </Row>
          <Row type="flex" align="middle" justify="center">
            <Button type="link" onClick={() => handleNavigation('/')}>
              {t('authentications.pages.register.login')}
            </Button>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default RegisterPage;
