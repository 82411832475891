/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';

import {
  Row,
  Col,
  Form,
  Space,
  Input,
  Button,
} from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import GraphQLErrorAlert from '../../commons/components/GraphQLErrorAlert';

const ResetPasswordForm = (props) => {
  const {
    loading,
    onSubmit,
    serverErrors,
  } = props;

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation();

  const passwordWatch = watch('password');

  const renderServerErrors = () => {
    if (serverErrors) {
      return (
        <Row gutter={16}>
          <Col flex={1}>
            <GraphQLErrorAlert error={serverErrors} />
          </Col>
        </Row>
      );
    }
    return null;
  };

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        {renderServerErrors()}
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <Form.Item
              label={t('authentications.components.resetPasswordForm.passwordLabel')}
              validateStatus={errors != null && errors.password != null ? 'error' : null}
              help={errors && errors.password && errors.password.message}
            >
              <Input.Password
                visibilityToggle
                value={field.value}
                onChange={field.onChange}
                size="large"
                prefix={<LockOutlined />}
              />
            </Form.Item>
          )}
          rules={{
            required: t('validations.required'),
          }}
        />
        <Controller
          name="passwordConfirmation"
          control={control}
          render={({ field }) => (
            <Form.Item
              label={t('authentications.components.resetPasswordForm.passwordConfirmationLabel')}
              validateStatus={errors != null && errors.passwordConfirmation != null ? 'error' : null}
              help={errors && errors.passwordConfirmation && errors.passwordConfirmation.message}
            >
              <Input.Password
                visibilityToggle
                value={field.value}
                onChange={field.onChange}
                size="large"
                prefix={<LockOutlined />}
              />
            </Form.Item>
          )}
          rules={{
            validate: {
              match: (v) => (v !== passwordWatch ? t('validations.passwordMatch') : true),
            },
            required: t('validations.required'),
          }}
        />
        <Button
          loading={loading}
          block
          type="primary"
          className="login-form-button"
          htmlType="submit"
        >
          {t('authentications.components.resetPasswordForm.submit')}
        </Button>
      </Space>
    </Form>
  );
};

const propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  serverErrors: PropTypes.any,
};

const defaultProps = {
  serverErrors: null,
};

ResetPasswordForm.propTypes = propTypes;
ResetPasswordForm.defaultProps = defaultProps;

export default ResetPasswordForm;
