/* eslint-disable no-empty */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  PageHeader,
} from 'antd';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { CAMERA_VIEW_VIEWING_PERMISSION_CREATE_MUTATION } from '../queries';
import CameraViewViewingPermissionForm from '../components/Form';

const CreatePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [
    createMutationAction,
    {
      loading: mutationLoading,
      error: mutationError,
    },
  ] = useMutation(CAMERA_VIEW_VIEWING_PERMISSION_CREATE_MUTATION);

  const handleSubmit = React.useCallback(async (newData) => {
    const result = {};
    if (newData.name) {
      result.name = newData.name;
    }
    if (newData.user) {
      result.user = newData.user;
    }
    if (newData.cameraView) {
      result.cameraViewId = newData.cameraView.value;
    }
    try {
      await createMutationAction({
        variables: {
          input: {
            ...result,
          },
        },
      });
      navigate(-1);
    }
    catch (e) { }
  }, [navigate, createMutationAction]);

  return (
    <>
      <Row gutter={16}>
        <Col flex={1}>
          <PageHeader
            title={t('cameraViewViewingPermissions.pages.create.title')}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col flex={1}>
          <CameraViewViewingPermissionForm
            loading={mutationLoading}
            onSubmit={handleSubmit}
            serverErrors={mutationError}
          />
        </Col>
      </Row>
    </>
  );
};

export default CreatePage;
