/* eslint-disable no-empty */
import React from 'react';
import {
  PageHeader,
  Row,
  Col,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import { CAMERA_VIEW_EDIT_QUERY, CAMERA_VIEW_UPDATE_MUTATION } from '../queries';
import GraphQLErrorAlert from '../../commons/components/GraphQLErrorAlert';
import Loader from '../../commons/components/Loader';
import CameraViewForm from '../components/Form';

const EditPage = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data, error, loading,
  } = useQuery(CAMERA_VIEW_EDIT_QUERY, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  const queryResult = React.useMemo(() => {
    if (!loading && !error) {
      const { viewer } = data;
      const { cameraView } = viewer;
      return {
        cameraView,
      };
    }
    return {
      cameraView: null,
    };
  }, [data, error, loading]);

  const entity = React.useMemo(() => {
    if (queryResult.cameraView) {
      const result = {
        name: queryResult.cameraView.name,
        available: queryResult.cameraView.available,
        timeout: 10,
        sourceImageConfig: queryResult.cameraView.sourceImageConfig,
        cropAndMask: {
          cropData: queryResult.cameraView.cropData,
          maskData: queryResult.cameraView.maskData,
        },
      };
      return result;
    }
    return null;
  }, [queryResult]);

  const title = React.useMemo(() => {
    if (queryResult.cameraView) {
      return `${t('cameraViews.pages.edit.title')} - ${queryResult.cameraView.name}`;
    }
    return t('cameraViews.pages.edit.title');
  }, [queryResult]);

  const [
    updateMutationAction,
    {
      loading: mutationLoading,
      error: mutationError,
    },
  ] = useMutation(CAMERA_VIEW_UPDATE_MUTATION);

  const handleSubmit = React.useCallback(async (newData) => {
    try {
      await updateMutationAction({
        variables: {
          input: {
            id: queryResult.cameraView.id,
            name: newData.name,
            available: newData.available,
            timeout: newData.timeout,
            sourceImageConfig: newData.sourceImageConfig,
            cropData: newData.cropAndMask.cropData,
            maskData: newData.cropAndMask.maskData,
            maskImage: newData.cropAndMask.maskImage,
          },
        },
      });
      navigate(-1);
    }
    catch (e) { }
  }, [updateMutationAction, navigate, queryResult]);

  const renderServerErrors = () => {
    if (error) {
      return (
        <Row gutter={16}>
          <Col flex={1}>
            <GraphQLErrorAlert error={error} />
          </Col>
        </Row>
      );
    }
    return null;
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Row justify="center" align="middle" style={{ height: '100%' }}>
          <Loader />
        </Row>
      );
    }

    return (
      <CameraViewForm
        loading={mutationLoading}
        onSubmit={handleSubmit}
        serverErrors={mutationError}
        entity={entity}
      />
    );
  };

  return (
    <>
      <Row gutter={16}>
        <Col flex={1}>
          <PageHeader
            title={title}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col flex={1}>
          {renderServerErrors()}
          {renderContent()}
        </Col>
      </Row>
    </>
  );
};

export default EditPage;
