import { gql } from '@apollo/client';

export const USER_CONFIRM_MUTATION = gql`
  mutation UserConfirmMutation($input: UserConfirmInput!) {
    userConfirm(input: $input) {
      token
    }
  }
`;

export const USER_CHANGE_PASSWORD_MUTATION = gql`
  mutation UserChangePasswordMutation($input: UserChangePasswordInput!) {
    userChangePassword(input: $input) {
      success
    }
  }
`;
