/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Select, Spin } from 'antd';
import { useDebouncedCallback } from 'use-debounce';

const DebounceSelect = (props) => {
  const {
    fetchOptions,
    debounceTimeout,
    fetching,
    options,
    ...rest
  } = props;

  const debounced = useDebouncedCallback((value) => {
    fetchOptions(value);
  }, debounceTimeout);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounced}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...rest}
      options={options}
      showSearch
    />
  );
};

export default DebounceSelect;
