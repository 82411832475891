/* eslint-disable no-empty */
import React from 'react';
import {
  PageHeader,
  Row,
  Col,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { CAMERA_VIEW_CREATE_MUTATION } from '../queries';
import CameraViewForm from '../components/Form';

const CreatePage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [
    createMutationAction,
    {
      loading: mutationLoading,
      error: mutationError,
    },
  ] = useMutation(CAMERA_VIEW_CREATE_MUTATION);

  const handleSubmit = React.useCallback(async (newData) => {
    try {
      await createMutationAction({
        variables: {
          input: {
            name: newData.name,
            available: newData.available,
            timeout: 10,
            sourceImageConfig: newData.sourceImageConfig,
            cropData: newData.cropAndMask.cropData,
            maskData: newData.cropAndMask.maskData,
            maskImage: newData.cropAndMask.maskImage,
          },
        },
      });
      navigate(-1);
    }
    catch (e) { }
  }, [createMutationAction, navigate]);

  return (
    <>
      <Row gutter={16}>
        <Col flex={1}>
          <PageHeader
            title={t('cameraViews.pages.create.title')}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col flex={1}>
          <CameraViewForm
            loading={mutationLoading}
            onSubmit={handleSubmit}
            serverErrors={mutationError}
          />
        </Col>
      </Row>
    </>
  );
};

export default CreatePage;
