/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import {
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import {
  Row,
  Col,
  Button,
  Space,
  Alert,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const ImageViewer = (props) => {
  const {
    id,
    imageUrl,
  } = props;
  const { t } = useTranslation();

  const [error, setError] = React.useState(false);

  const [zoomAndPan, setZoomAndPan] = React.useState(
    localStorage.getItem(`zoomAndPan-${id}`)
      ? JSON.parse(localStorage.getItem(`zoomAndPan-${id}`))
      : {
        initialScale: 1,
        initialPositionX: 0,
        initialPositionY: 0,
      },
  );

  const handlePanningStop = React.useCallback((ref) => {
    const result = {
      initialScale: ref.state.scale,
      initialPositionX: ref.state.positionX,
      initialPositionY: ref.state.positionY,
    };
    setZoomAndPan(result);
    localStorage.setItem(`zoomAndPan-${id}`, JSON.stringify(result));
  }, [zoomAndPan, setZoomAndPan]);

  const handleZoomStop = React.useCallback((ref) => {
    const result = {
      initialScale: ref.state.scale,
      initialPositionX: ref.state.positionX,
      initialPositionY: ref.state.positionY,
    };
    setZoomAndPan(result);
    localStorage.setItem(`zoomAndPan-${id}`, JSON.stringify(result));
  }, [zoomAndPan, setZoomAndPan]);

  const handleError = React.useCallback(() => {
    setError(true);
  }, [setError]);

  if (error) {
    return (
      <Space style={{ width: '100%' }} direction="vertical" size="large">
        <Row justify="center">
          <Col>
            <Alert type="error" message={t('cameraViews.pages.detail.notAvailable')} />
          </Col>
        </Row>
      </Space>
    );
  }

  return (
    <TransformWrapper
      initialScale={zoomAndPan.initialScale}
      initialPositionX={zoomAndPan.initialPositionX}
      initialPositionY={zoomAndPan.initialPositionY}
      onPanningStop={handlePanningStop}
      onZoomStop={handleZoomStop}
    >
      {({ zoomIn, zoomOut }) => (
        <Space style={{ width: '100%' }} direction="vertical" size="large">
          <Row justify="center">
            <Col>
              <TransformComponent>
                <img
                  width="100%"
                  src={imageUrl}
                  alt={id}
                  onError={handleError}
                />
              </TransformComponent>
            </Col>
          </Row>
          <Row justify="space-around">
            <Button
              type="primary"
              icon={<ZoomInOutlined />}
              onClick={() => zoomIn()}
            >
              {t('cameraViews.pages.detail.zoomIn')}
            </Button>
            <Button
              type="primary"
              icon={<ZoomOutOutlined />}
              onClick={() => zoomOut()}
            >
              {t('cameraViews.pages.detail.zoomOut')}
            </Button>
          </Row>
        </Space>
      )}
    </TransformWrapper>
  );
};

const propTypes = {
  id: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

ImageViewer.propTypes = propTypes;

export default ImageViewer;
