/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';

import {
  Row,
  Col,
  Form,
  Space,
  Input,
  Button,
} from 'antd';
import { useTranslation } from 'react-i18next';
import GraphQLErrorAlert from '../../commons/components/GraphQLErrorAlert';

import CameraViewInput from '../../cameraViews/components/CameraViewInput';

const CameraViewViewingPermissionForm = (props) => {
  const {
    loading,
    onSubmit,
    serverErrors,
    entity,
  } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: entity });

  const { t } = useTranslation();

  const renderServerErrors = () => {
    if (serverErrors) {
      return (
        <Row gutter={16}>
          <Col flex={1}>
            <GraphQLErrorAlert error={serverErrors} />
          </Col>
        </Row>
      );
    }
    return null;
  };

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        {renderServerErrors()}
        <Row gutter={16}>
          <Col flex={1}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('cameraViewViewingPermissions.components.form.nameLabel')}
                  validateStatus={errors != null && errors.name != null ? 'error' : null}
                  help={errors && errors.name && errors.name.message}
                >
                  <Input
                    value={field.value}
                    onChange={field.onChange}
                    size="large"
                  />
                </Form.Item>
              )}
              rules={{
                required: t('validations.required'),
              }}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col flex={1}>
            <Controller
              name="user"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('cameraViewViewingPermissions.components.form.userLabel')}
                  validateStatus={errors != null && errors.user != null ? 'error' : null}
                  help={errors && errors.user && errors.user.message}
                >
                  <Input
                    value={field.value}
                    onChange={field.onChange}
                    size="large"
                    allowClear
                  />
                </Form.Item>
              )}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col flex={1}>
            <Controller
              name="cameraView"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('cameraViewViewingPermissions.components.form.cameraViewLabel')}
                  validateStatus={errors != null && errors.cameraView != null ? 'error' : null}
                  help={errors && errors.cameraView && errors.cameraView.message}
                >
                  <CameraViewInput
                    value={field.value}
                    onChange={field.onChange}
                    onlyMine
                  />
                </Form.Item>
              )}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col flex={1}>
            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('cameraViewViewingPermissions.components.form.noteLabel')}
                  validateStatus={errors != null && errors.note != null ? 'error' : null}
                  help={errors && errors.note && errors.note.message}
                >
                  <Input.TextArea
                    rows={4}
                    value={field.value}
                    onChange={field.onChange}
                  />
                </Form.Item>
              )}
            />
          </Col>
        </Row>
        <Button
          loading={loading}
          block
          type="primary"
          htmlType="submit"
        >
          {entity ? t('common.save') : t('common.create')}
        </Button>
      </Space>
    </Form>
  );
};

const propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  serverErrors: PropTypes.any,
  entity: PropTypes.any,
};

const defaultProps = {
  entity: null,
  serverErrors: null,
};

CameraViewViewingPermissionForm.propTypes = propTypes;
CameraViewViewingPermissionForm.defaultProps = defaultProps;

export default CameraViewViewingPermissionForm;
