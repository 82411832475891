/* eslint-disable no-empty */
import React from 'react';
import {
  PageHeader,
  Row,
  Col,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { USER_CHANGE_PASSWORD_MUTATION } from '../queries';
import ChangePasswordForm from '../components/ChangePasswordForm';
import { AuthenticationContext } from '../../authentications/contexts/AuthenticationContext';

const EditPage = () => {
  const { t } = useTranslation();

  const { currentUser } = React.useContext(AuthenticationContext);

  const navigate = useNavigate();

  const [
    changePasswordMutationAction,
    {
      loading: mutationLoading,
      error: mutationError,
    },
  ] = useMutation(USER_CHANGE_PASSWORD_MUTATION);

  const handleSubmit = React.useCallback(async (newData) => {
    try {
      await changePasswordMutationAction({
        variables: {
          input: {
            currentPassword: newData.currentPassword,
            password: newData.password,
          },
        },
      });
      navigate(-1);
    }
    catch (e) { }
  }, [changePasswordMutationAction, navigate]);

  const renderContent = () => (
    <ChangePasswordForm
      loading={mutationLoading}
      onSubmit={handleSubmit}
      serverErrors={mutationError}
    />
  );

  return (
    <>
      <Row gutter={16}>
        <Col flex={1}>
          <PageHeader
            title={`${t('users.pages.edit.title')} ${currentUser.sub}`}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col flex={1}>
          {renderContent()}
        </Col>
      </Row>
    </>
  );
};

export default EditPage;
