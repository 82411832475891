/* eslint-disable no-empty */
import React from 'react';

import {
  Row,
  Col,
  Card,
  Image,
  Space,
  Button,
} from 'antd';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import logo from '../../assets/images/logo.png';
import LoginForm from '../components/LoginForm';

import { AUTHENTICATION_LOGIN_MUTATION } from '../queries';
import { AuthenticationContext } from '../contexts/AuthenticationContext';

const LoginPage = () => {
  const { t } = useTranslation();
  const { login } = React.useContext(AuthenticationContext);

  const [
    loginMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(AUTHENTICATION_LOGIN_MUTATION);

  const navigate = useNavigate();

  const handleNavigation = React.useCallback((path) => {
    navigate(path);
  }, [navigate]);

  const handleSubmit = React.useCallback(async (data) => {
    try {
      const result = await loginMutationAction({
        variables: {
          input: {
            ...data,
          },
        },
      });
      if (result.data && result.data.authenticationLogin) {
        login(result.data.authenticationLogin.token);
      }
      navigate('/', { replace: true });
    }
    catch (e) { }
  }, [loginMutationAction]);

  return (
    <Row type="flex" justify="center" align="middle" style={{ height: '100vh' }}>
      <Col xs={24} sm={8}>
        <Card>
          <Space direction="vertical">
            <Row type="flex" align="middle" justify="center">
              <Image src={logo} preview={false} />
            </Row>
            <LoginForm
              loading={loading}
              serverErrors={error}
              onSubmit={handleSubmit}
            />
          </Space>
          <Row type="flex" align="middle" justify="center">
            <Button
              key="recoverPasswordLink"
              type="link"
              onClick={() => handleNavigation('/recoverPassword')}
            >
              {t('authentications.pages.login.recoverPassword')}
            </Button>
          </Row>
          <Row type="flex" align="middle" justify="center">
            <Button key="registerLink" type="link" onClick={() => handleNavigation('/register')}>
              {t('authentications.pages.login.register')}
            </Button>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default LoginPage;
