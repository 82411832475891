import { gql } from '@apollo/client';

export const AUTHENTICATION_LOGIN_MUTATION = gql`
  mutation LoginMutation($input: AuthenticationLoginInput!) {
    authenticationLogin(input: $input) {
      token
    }
  }
`;

export const AUTHENTICATION_REGISTER_MUTATION = gql`
  mutation RegisterMutation($input: AuthenticationRegisterInput!) {
    authenticationRegister(input: $input) {
      success
    }
  }
`;

export const AUTHENTICATION_RECOVER_PASSWORD_MUTATION = gql`
  mutation RecoverPasswordMutation($input: AuthenticationRecoverPasswordInput!) {
    authenticationRecoverPassword(input: $input) {
      success
    }
  }
`;

export const AUTHENTICATION_RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordMutation($input: AuthenticationResetPasswordInput!) {
    authenticationResetPassword(input: $input) {
      success
    }
  }
`;
