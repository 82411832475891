import { gql } from '@apollo/client';

export const CAMERA_VIEW_VIEWING_PERMISSION_LIST_FRAGMENT = gql`
  fragment CameraViewViewingPermissionListFragment on CameraViewViewingPermission {
    id
    name
    user
    note
    createdAt
    cameraView {
      id
      name
      iAmOwner
    }
  }
`;

export const CAMERA_VIEW_VIEWING_PERMISSIONS_QUERY = gql`
query cameraViewViewingPermissionsQuery($afterCursor: String, $pageSize: Int!, $filter: CameraViewViewingPermissionFilter) {
  viewer {
    id
    cameraViewViewingPermissions(first: $pageSize, after: $afterCursor, filter: $filter) {
      edges {
        node {
          id
          ...CameraViewViewingPermissionListFragment
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
}
${CAMERA_VIEW_VIEWING_PERMISSION_LIST_FRAGMENT}
`;

export const CAMERA_VIEW_VIEWING_PERMISSION_QUERY = gql`
query cameraViewViewingPermissionQuery($id: ID!) {
  viewer {
    id
    cameraViewViewingPermission(id: $id) {
      ...CameraViewViewingPermissionListFragment
    }
  }
}
${CAMERA_VIEW_VIEWING_PERMISSION_LIST_FRAGMENT}
`;

export const CAMERA_VIEW_VIEWING_PERMISSION_CREATE_MUTATION = gql`
  mutation CameraViewViewingPermissionCreateMutation($input: CameraViewViewingPermissionCreateInput!) {
    cameraViewViewingPermissionCreate(input: $input) {
      cameraViewViewingPermission {
        id
        ...CameraViewViewingPermissionListFragment
      }
    }
  }
  ${CAMERA_VIEW_VIEWING_PERMISSION_LIST_FRAGMENT}
`;

export const CAMERA_VIEW_VIEWING_PERMISSION_UPDATE_MUTATION = gql`
  mutation CameraViewViewingPermissionUpdateMutation($input: CameraViewViewingPermissionUpdateInput!) {
    cameraViewViewingPermissionUpdate(input: $input) {
      cameraViewViewingPermission {
        id
        ...CameraViewViewingPermissionListFragment
      }
    }
  }
  ${CAMERA_VIEW_VIEWING_PERMISSION_LIST_FRAGMENT}
`;

export const CAMERA_VIEW_VIEWING_PERMISSION_DELETE_MUTATION = gql`
  mutation CameraViewViewingPermissionDeleteMutation($input: CameraViewViewingPermissionDeleteInput!) {
    cameraViewViewingPermissionDelete(input: $input) {
      success
    }
  }
`;
