/* eslint-disable max-len */
import React from 'react';
import {
  Row,
  Col,
  Button,
  Empty,
  Space,
  PageHeader,
  Table,
  Popconfirm,
} from 'antd';
import {
  DeleteOutlined,
  EyeOutlined,
  PlusOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { CAMERA_VIEW_VIEWING_PERMISSIONS_QUERY, CAMERA_VIEW_VIEWING_PERMISSION_DELETE_MUTATION } from '../queries';
import GraphQLErrorAlert from '../../commons/components/GraphQLErrorAlert';
import Filter from '../components/Filter';
import Loader from '../../commons/components/Loader';

const pageSize = 20;

const ListPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const queryCameraView = React.useMemo(() => {
    const cameraView = searchParams.get('cameraView');
    return cameraView;
  }, [searchParams]);

  const [filter, setFilter] = React.useState({
    search: queryCameraView,
  });

  const {
    data,
    error,
    loading,
    fetchMore,
    refetch,
  } = useQuery(CAMERA_VIEW_VIEWING_PERMISSIONS_QUERY, {
    variables: {
      pageSize,
      filter,
    },
    fetchPolicy: 'network-only',
  });

  const [
    deleteMutationAction,
  ] = useMutation(CAMERA_VIEW_VIEWING_PERMISSION_DELETE_MUTATION);

  const handleFilterChange = React.useCallback((newFilter) => {
    setFilter(newFilter);
  }, [setFilter]);

  const queryResult = React.useMemo(() => {
    if (!loading && !error) {
      const { viewer } = data;
      const { cameraViewViewingPermissions: cameraViewViewingPermissionConnection } = viewer;
      const { pageInfo, edges } = cameraViewViewingPermissionConnection;
      return {
        cameraViewViewingPermissions: edges.map((e) => e.node),
        pageInfo,
      };
    }
    return {
      cameraViewViewingPermissions: [],
      pageInfo: null,
    };
  }, [data, error, loading]);

  const handleViewClick = React.useCallback((cameraViewVievingPermission) => {
    navigate(`/cameraViews/${cameraViewVievingPermission.cameraView.id}`);
  }, [navigate]);

  const handleEditClick = React.useCallback((cameraViewViewingPermission) => {
    navigate(`/cameraViewViewingPermissions/edit/${cameraViewViewingPermission.id}`);
  }, [navigate]);

  const handleDeleteClick = React.useCallback(async (cameraView) => {
    await deleteMutationAction({
      variables: {
        input: {
          id: cameraView.id,
        },
      },
    });
    refetch({
      variables: {
        pageSize,
        filter,
      },
    });
  }, [deleteMutationAction, refetch, pageSize, filter]);

  const columns = React.useMemo(() => {
    const result = [];
    result.push({
      title: t('cameraViewViewingPermissions.pages.list.tableColumn.name'),
      dataIndex: 'name',
      key: 'name',
    });
    result.push({
      title: t('cameraViewViewingPermissions.pages.list.tableColumn.user'),
      dataIndex: 'user',
      key: 'user',
    });
    result.push({
      title: t('cameraViewViewingPermissions.pages.list.tableColumn.cameraView'),
      dataIndex: 'cameraView',
      key: 'cameraView',
      render: (cameraView) => cameraView.name,
    });
    result.push({
      title: t('cameraViewViewingPermissions.pages.list.tableColumn.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => {
        const formatted = format(new Date(createdAt), 'Ppp', { locale: it });
        return formatted;
      },
    });
    result.push({
      title: t('cameraViewViewingPermissions.pages.list.tableColumn.actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        const actions = [];
        actions.push((
          <Button
            key="view"
            shape="circle"
            icon={<EyeOutlined />}
            type="primary"
            ghost
            onClick={() => handleViewClick(record)}
          />
        ));
        if (record.cameraView.iAmOwner) {
          actions.push((
            <Button
              key="view"
              shape="circle"
              icon={<EditOutlined />}
              type="primary"
              ghost
              onClick={() => handleEditClick(record)}
            />
          ));
          actions.push((
            <Popconfirm
              key="delete"
              placement="topLeft"
              title={t('common.sureToDelete')}
              onConfirm={() => handleDeleteClick(record)}
              okText={t('common.yes')}
              cancelText={t('common.no')}
            >
              <Button
                shape="circle"
                icon={<DeleteOutlined />}
                type="primary"
                ghost
              />
            </Popconfirm>
          ));
        }
        return (
          <Space size="middle">
            {actions}
          </Space>
        );
      },
    });
    return result;
  }, []);

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        afterCursor: queryResult.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const { edges: oldEdges } = previousResult.viewer.cameraViewViewingPermissions;
        const { pageInfo: newPageInfo, edges: newEdges } = fetchMoreResult.viewer.cameraViewViewingPermissions;
        return newEdges.length
          ? {
            ...previousResult,
            viewer: {
              ...previousResult.viewer,
              cameraViewViewingPermissions: {
                ...previousResult.viewer.camera.cameraViewViewingPermissions,
                edges: [...oldEdges, ...newEdges],
                pageInfo: newPageInfo,
              },
            },
          }
          : previousResult;
      },
    });
  };

  const renderError = () => {
    if (error) {
      return (
        <Row gutter={16}>
          <Col flex={1}>
            <GraphQLErrorAlert error={error} />
          </Col>
        </Row>
      );
    }
    return null;
  };

  const renderLoadMore = () => {
    if (queryResult.pageInfo && queryResult.pageInfo.hasNextPage) {
      return (
        <Row type="flex" justify="center" align="middle">
          <Col>
            <Button loading={loading} type="primary" onClick={handleLoadMore}>
              {t('common.loadMore')}
            </Button>
          </Col>
        </Row>
      );
    }

    return null;
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Row justify="center" align="middle" style={{ height: '100%' }}>
          <Loader />
        </Row>
      );
    }
    if (queryResult.cameraViewViewingPermissions.length === 0) {
      return (
        <Empty
          description={t('common.noData')}
        />
      );
    }

    const { cameraViewViewingPermissions } = queryResult;
    return (
      <Row gutter={[16, 16]}>
        <Col flex={1}>
          <Table
            pagination={false}
            columns={columns}
            dataSource={cameraViewViewingPermissions}
          />
        </Col>
      </Row>
    );
  };

  const renderFilter = () => (
    <Row>
      <Col flex={1}>
        <Filter
          filter={filter}
          onFilterChange={handleFilterChange}
        />
      </Col>
    </Row>
  );

  const renderExtra = () => (
    <Button type="action" icon={<PlusOutlined />} onClick={() => navigate('/cameraViewViewingPermissions/new')}>
      {t('cameraViewViewingPermissions.pages.list.createButtonText')}
    </Button>
  );

  return (
    <>
      <Row gutter={16}>
        <Col flex={1}>
          <PageHeader
            title={t('cameraViewViewingPermissions.pages.list.title')}
            extra={renderExtra()}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col flex={1}>
          <Space style={{ width: '100%' }} direction="vertical" size="large">
            {renderError()}
            {renderFilter()}
            {renderContent()}
            {renderLoadMore()}
          </Space>
        </Col>
      </Row>
      <Outlet />
    </>
  );
};

export default ListPage;
