/* eslint-disable no-empty */
import React from 'react';

import {
  Row,
  Col,
  Card,
  Image,
  Space,
  Button,
  Alert,
} from 'antd';
import { useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo.png';

import { AUTHENTICATION_RESET_PASSWORD_MUTATION } from '../queries';
import ResetPasswordForm from '../components/ResetPasswordForm';

const RecoverPasswordPage = () => {
  const { t } = useTranslation();
  const { token } = useParams();

  const [completed, setCompleted] = React.useState(false);

  const [
    resetPasswordMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(AUTHENTICATION_RESET_PASSWORD_MUTATION);

  const navigate = useNavigate();

  const handleNavigation = React.useCallback((path) => {
    navigate(path);
  }, [navigate]);

  const handleSubmit = React.useCallback(async (data) => {
    try {
      await resetPasswordMutationAction({
        variables: {
          input: {
            token,
            password: data.password,
          },
        },
      });
      setCompleted(true);
    }
    catch (e) { }
  }, [resetPasswordMutationAction, setCompleted]);

  return (
    <Row type="flex" justify="center" align="middle" style={{ height: '100vh' }}>
      <Col xs={24} sm={8}>
        <Card>
          <Space direction="vertical">
            <Row type="flex" align="middle" justify="center">
              <Image src={logo} preview={false} />
            </Row>
            {!completed && (
              <ResetPasswordForm
                loading={loading}
                serverErrors={error}
                onSubmit={handleSubmit}
              />
            )}
            {completed && (
              <Alert
                type="success"
                message={t('authentications.pages.resetPassword.successMessage')}
                description={t('authentications.pages.resetPassword.successDescription')}
              />
            )}
          </Space>
          <Row type="flex" align="middle" justify="center">
            <Button
              type="link"
              onClick={() => handleNavigation('/')}
            >
              {t('authentications.pages.resetPassword.login')}
            </Button>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default RecoverPasswordPage;
