/* eslint-disable no-empty */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  PageHeader,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';
import {
  CAMERA_VIEW_VIEWING_PERMISSION_QUERY,
  CAMERA_VIEW_VIEWING_PERMISSION_UPDATE_MUTATION,
} from '../queries';
import CameraViewViewingPermissionForm from '../components/Form';
import Loader from '../../commons/components/Loader';
import GraphQLErrorAlert from '../../commons/components/GraphQLErrorAlert';

const CreatePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  const {
    data,
    loading,
    error,
  } = useQuery(CAMERA_VIEW_VIEWING_PERMISSION_QUERY, {
    variables: {
      id,
    },
  });

  const [
    updateMutationAction,
    {
      loading: mutationLoading,
      error: mutationError,
    },
  ] = useMutation(CAMERA_VIEW_VIEWING_PERMISSION_UPDATE_MUTATION);

  const queryResult = React.useMemo(() => {
    if (!loading && !error) {
      const { viewer } = data;
      const { cameraViewViewingPermission } = viewer;
      return {
        cameraViewViewingPermission,
      };
    }
    return {
      cameraViewViewingPermission: null,
    };
  }, [data, error, loading]);

  const entity = React.useMemo(() => {
    if (queryResult.cameraViewViewingPermission) {
      const result = {
        name: queryResult.cameraViewViewingPermission.name,
        user: queryResult.cameraViewViewingPermission.user,
        cameraView: {
          label: queryResult.cameraViewViewingPermission.cameraView.name,
          value: queryResult.cameraViewViewingPermission.cameraView.id,
        },
        note: queryResult.cameraViewViewingPermission.note,
      };
      return result;
    }
    return null;
  }, [queryResult]);

  const handleSubmit = React.useCallback(async (newData) => {
    const result = {};
    if (newData.name) {
      result.name = newData.name;
    }
    if (newData.user) {
      result.user = newData.user;
    }
    if (newData.cameraView) {
      result.cameraViewId = newData.cameraView.value;
    }
    if (newData.cameraView) {
      result.note = newData.note;
    }
    try {
      await updateMutationAction({
        variables: {
          input: {
            id,
            ...result,
          },
        },
      });
      navigate(-1);
    }
    catch (e) { }
  }, [navigate, updateMutationAction]);

  const renderServerErrors = () => {
    if (error) {
      return (
        <Row gutter={16}>
          <Col flex={1}>
            <GraphQLErrorAlert error={error} />
          </Col>
        </Row>
      );
    }
    return null;
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Row justify="center" align="middle" style={{ height: '100%' }}>
          <Loader />
        </Row>
      );
    }

    return (
      <CameraViewViewingPermissionForm
        loading={mutationLoading}
        onSubmit={handleSubmit}
        serverErrors={mutationError}
        entity={entity}
      />
    );
  };

  return (
    <>
      <Row gutter={16}>
        <Col flex={1}>
          <PageHeader
            title={t('cameraViewViewingPermissions.pages.edit.title')}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col flex={1}>

          {renderServerErrors()}
          {renderContent()}
        </Col>
      </Row>
    </>
  );
};

export default CreatePage;
