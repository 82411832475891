import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import Router from './Router';
import apolloClient from './apollo';
import 'react-image-crop/dist/ReactCrop.css';
import './App.less';

import { AuthenticationProvider } from './authentications/contexts/AuthenticationContext';

const App = () => (
  <ApolloProvider client={apolloClient}>
    <BrowserRouter>
      <AuthenticationProvider>
        <Router />
      </AuthenticationProvider>
    </BrowserRouter>
  </ApolloProvider>
);

export default App;
