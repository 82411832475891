/* eslint-disable max-len */
import React from 'react';

import {
  RedoOutlined,
} from '@ant-design/icons';
import {
  Row,
  Col,
  Button,
  PageHeader,
  Space,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery, NetworkStatus } from '@apollo/client';
import { differenceInSeconds, format } from 'date-fns';
import { it } from 'date-fns/locale';
import { CAMERA_VIEW_SHOW_QUERY } from '../queries';
import GraphQLErrorAlert from '../../commons/components/GraphQLErrorAlert';
import Loader from '../../commons/components/Loader';
import { AuthenticationContext } from '../../authentications/contexts/AuthenticationContext';
import ImageViewer from '../components/ImageViewer';

const DetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { accessToken } = React.useContext(AuthenticationContext);
  const [queryResult, setQueryResult] = React.useState({ cameraView: null });
  const [secondDifference, setSecondDifference] = React.useState(null);

  const {
    data,
    error,
    loading,
    refetch,
    networkStatus,
  } = useQuery(CAMERA_VIEW_SHOW_QUERY, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  React.useEffect(() => {
    if (!loading && !error) {
      const { viewer } = data;
      const { cameraView } = viewer;
      setQueryResult({ cameraView });
    }
  }, [data, error, loading, setQueryResult]);

  const realImageUrl = React.useMemo(() => {
    if (queryResult.cameraView != null) {
      const timestamp = new Date().valueOf();
      return `${queryResult.cameraView.imageUrl}?access_token=${accessToken}&timestamp=${timestamp}`;
    }
    return null;
  }, [queryResult]);

  const title = React.useMemo(() => {
    if (queryResult.cameraView != null) {
      return queryResult.cameraView.name;
    }
    return t('cameraViews.pages.detail.title');
  }, [queryResult]);

  const time = React.useMemo(() => {
    if (queryResult.cameraView != null) {
      const dateFormatted = format(new Date(queryResult.cameraView.lastImageDate), 'Ppp', { locale: it });
      return `${t('cameraViews.pages.detail.time')}${dateFormatted}`;
    }
    return null;
  }, [queryResult]);

  const updateButtonText = React.useMemo(() => {
    if (queryResult.cameraView) {
      if (networkStatus === NetworkStatus.refetch) {
        return t('cameraViews.pages.detail.updating');
      }
      if (secondDifference <= queryResult.cameraView.timeout) {
        return t('cameraViews.pages.detail.wait', { time: queryResult.cameraView.timeout - secondDifference });
      }
      return t('cameraViews.pages.detail.update');
    }
    return null;
  }, [queryResult, secondDifference, networkStatus]);

  React.useEffect(() => {
    if (queryResult.cameraView) {
      const { lastImageDate } = queryResult.cameraView;
      if (lastImageDate) {
        const now = new Date();
        const lastImageRealDate = new Date(lastImageDate);
        const difference = differenceInSeconds(now, lastImageRealDate);
        setSecondDifference(difference);
      }
    }
  }, [queryResult, setSecondDifference]);

  React.useEffect(() => {
    if (queryResult.cameraView) {
      const timeout = setTimeout(() => {
        setSecondDifference(secondDifference + 1);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
    return () => { };
  }, [queryResult, secondDifference, setSecondDifference]);

  const renderError = () => {
    if (error) {
      return (
        <Row gutter={16}>
          <Col flex={1}>
            <GraphQLErrorAlert error={error} />
          </Col>
        </Row>
      );
    }
    return null;
  };

  const renderContent = () => {
    if (error) {
      return null;
    }

    if (!queryResult.cameraView) {
      return (
        <Row justify="center" align="middle" style={{ height: '100%' }}>
          <Loader />
        </Row>
      );
    }

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col flex={1}>
            <Space style={{ width: '100%' }} direction="horizontal" size="large">
              <Button
                disabled={secondDifference <= queryResult.cameraView.timeout}
                type="primary"
                icon={<RedoOutlined />}
                loading={networkStatus === NetworkStatus.refetch}
                onClick={() => {
                  refetch();
                }}
              >
                {updateButtonText}
              </Button>
              <Typography.Text type="secondary">{time}</Typography.Text>
            </Space>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col flex={1}>
            <ImageViewer
              id={id}
              imageUrl={realImageUrl}
            />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Space style={{ width: '100%' }} direction="vertical" size="large">
      <Row gutter={16}>
        <Col flex={1}>
          <PageHeader
            title={title}
          />
        </Col>
      </Row>
      {renderError()}
      {renderContent()}
    </Space>
  );
};

export default DetailPage;
