/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import { useApolloClient } from '@apollo/client';

import {
  useNavigate,
} from 'react-router-dom';

import { Row, Col } from 'antd';

import { USER_ROLES } from '../../enums';
import config from '../../config';
import Loader from '../../commons/components/Loader';

const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  if (!token) return false;

  const decoded = jwtDecode(token);
  const { exp } = decoded;
  const expirationDate = new Date(exp * 1000);

  if (new Date() > expirationDate) return false;
  return {
    ...decoded,
    token,
  };
};

const getCurrentUser = isAuthenticated;

export const AuthenticationContext = React.createContext();

export const AuthenticationProvider = (props) => {
  const { children } = props;
  const [currentUser, setCurrentUser] = React.useState(getCurrentUser());
  const [accessToken, setAccessToken] = React.useState(localStorage.getItem('token'));
  const apolloClient = useApolloClient();
  const navigate = useNavigate();

  const login = (token) => {
    localStorage.setItem('token', token);
    setAccessToken(token);
    setCurrentUser(getCurrentUser());
  };

  const logout = async () => {
    await apolloClient.resetStore();
    localStorage.removeItem('token');
    setAccessToken(null);
    setCurrentUser(getCurrentUser());
    navigate('/');
  };

  const iAmAdmin = React.useMemo(() => {
    if (currentUser && currentUser.user && currentUser.user.roles.includes(USER_ROLES.ADMIN)) {
      return true;
    }
    return false;
  }, [currentUser]);

  return (
    <AuthenticationContext.Provider
      value={{
        currentUser,
        accessToken,
        iAmAdmin,
        login,
        logout,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

AuthenticationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const { Consumer: AuthenticationConsumer } = AuthenticationContext;
