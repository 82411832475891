import React from 'react';
import {
  Layout,
} from 'antd';

import {
  Outlet,
} from 'react-router-dom';

const UnauthenticatedLayout = () => (
  <Layout style={{ minHeight: '100vh' }}>
    <Layout.Content>
      <Outlet />
    </Layout.Content>
  </Layout>
);

export default UnauthenticatedLayout;
