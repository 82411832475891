import React from 'react';
import {
  Routes,
  Route,
  Outlet,
} from 'react-router-dom';

import { AuthenticationContext } from './authentications/contexts/AuthenticationContext';

import UnauthenticatedLayout from './layouts/UnauthenticatedLayout';
import AuthenticatedLayout from './layouts/AuthenticatedLayout';

import LoginPage from './authentications/pages/LoginPage';
import RegisterPage from './authentications/pages/RegisterPage';
import RecoverPasswordPage from './authentications/pages/RecoverPasswordPage';
import ResetPasswordPage from './authentications/pages/ResetPasswordPage';

import ConfirmPage from './users/pages/ConfirmPage';

import CameraViewCreatePage from './cameraViews/pages/CreatePage';
import CameraViewEditPage from './cameraViews/pages/EditPage';
import CameraViewListPage from './cameraViews/pages/ListPage';
import CameraViewDetailPage from './cameraViews/pages/DetailPage';

import CameraViewViewingPermissionListPage from './cameraViewViewingPermissions/pages/ListPage';
import CameraViewViewingPermissionCreatePage from './cameraViewViewingPermissions/pages/CreatePage';
import CameraViewViewingPermissionEditPage from './cameraViewViewingPermissions/pages/EditPage';

import UserEditPage from './users/pages/EditPage';

const Router = () => {
  const { currentUser } = React.useContext(AuthenticationContext);

  if (!currentUser) {
    return (
      <Routes>
        <Route path="/" element={<UnauthenticatedLayout />}>
          <Route path="/" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/recoverPassword" element={<RecoverPasswordPage />} />
          <Route path="/resetPassword/:token" element={<ResetPasswordPage />} />
          <Route path="/userConfirm/:token" element={<ConfirmPage />} />
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<AuthenticatedLayout />}>
        <Route path="/" element={<CameraViewListPage />} />
        <Route path="/cameraViews" element={<Outlet />}>
          <Route path="/" element={<CameraViewListPage />} />
          <Route path="/new" element={<CameraViewCreatePage />} />
          <Route path="/edit/:id" element={<CameraViewEditPage />} />
          <Route path="/:id" element={<CameraViewDetailPage />} />
        </Route>
        <Route path="/cameraViewViewingPermissions" element={<Outlet />}>
          <Route path="/" element={<CameraViewViewingPermissionListPage />} />
          <Route path="/new" element={<CameraViewViewingPermissionCreatePage />} />
          <Route path="/edit/:id" element={<CameraViewViewingPermissionEditPage />} />
        </Route>
        <Route path="/profile" element={<UserEditPage />} />
      </Route>
    </Routes>
  );
};

export default Router;
