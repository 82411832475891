const translations = {
  translation: {
    menu: {
      cameraViews: 'Viste',
      cameraViewViewingPermissions: 'Permessi',
      profile: 'Profilo',
    },
    extractGraphQLErrors: {
      noDuplicate: 'Non sono permessi duplicati',
      networkError: 'Server non raggiungibile, controllare connessione',
      unexpectedError: 'Si è verificato un errore',
      unauthenticated: 'NON sei autenticato per visualizzare queste informazioni',
    },
    common: {
      someErrorOccurred: 'Errore',
      noData: 'Nessun dato da visualizzare',
      create: 'Crea',
      update: 'Aggiorna',
      loadMore: 'Carica ancora',
      filter: 'Filtra',
      sureToDelete: 'Sicuro di eliminare?',
      yes: 'Si',
      no: 'No',
      save: 'Salva',
    },
    validations: {
      required: 'Richiesto',
      passwordMatch: 'Le password non corrispondono',
    },
    authentications: {
      components: {
        loginForm: {
          emailLabel: 'Email',
          passwordLabel: 'Password',
          submit: 'Login',
        },
        registerForm: {
          emailLabel: 'Email',
          passwordLabel: 'Password',
          passwordConfirmationLabel: 'Conferma Password',
          submit: 'Registrati',
        },
        recoverPasswordForm: {
          emailLabel: 'Email',
          submit: 'Recupera password',
        },
        resetPasswordForm: {
          passwordLabel: 'Password',
          passwordConfirmationLabel: 'Conferma Password',
          submit: 'Reset password',
        },
      },
      pages: {
        login: {
          register: 'Registrati ora!',
          recoverPassword: 'Password dimenticata?',
        },
        register: {
          login: 'Ritorna al login!',
          recoverPassword: 'Password dimenticata?',
          successMessage: 'Registrazione avvenuta',
          successDescription: 'Riceverai una mail all\'indirizzo specificato con le istruzioni per confermare il tuo account',
        },
        recoverPassword: {
          register: 'Registrati ora!',
          login: 'Ritorna al login!',
          successMessage: 'Recupero password avviato',
          successDescription: 'Riceverai una mail all\'indirizzo specificato con le istruzioni per recuperare la tua password',
        },
        resetPassword: {
          successMessage: 'Password resettata',
          successDescription: 'Ora puoi effettuare il login con la nuova password',
          login: 'Ritorna al login!',
        },
      },
    },
    cameraViews: {
      components: {
        cropMaskInput: {
          clearButton: 'Resetta mask',
          selectOriginFirst: 'Seleziona un\'origine per avviare il crop',
          cut: 'Ritaglia',
          privacy: 'Privacy',
          loadingError: 'Errore nel recupero dell\'immagine. Controlla la configurazione',
        },
        sourceImageConfigInput: {
          type: 'Tipo di origine',
          set: 'Configurazione effettuata',
          unSet: 'Configurazione mancante',
          config: 'Configura',
          cameraTypes: {
            BOSH_FIXED: 'BOSCH - Telecamera IP fissa',
            BOSH_ENCODER: 'BOSCH - Encoder IP',
            HIKVISION_DVR: 'HIKVISION - DVR',
            DAHUA_DVR_NVR: 'DAHUA - DVR/NVR',
          },
        },
        boshFixedForm: {
          userLabel: 'User',
          passwordLabel: 'Password',
          ipLabel: 'Ip',
          portLabel: 'Porta',
        },
        boshEncoderForm: {
          userLabel: 'User',
          passwordLabel: 'Password',
          ipLabel: 'Ip',
          portLabel: 'Porta',
          channelLabel: 'Canale',
        },
        hikvisionDVR: {
          userLabel: 'User',
          passwordLabel: 'Password',
          ipLabel: 'Ip',
          portLabel: 'Porta',
          channelLabel: 'Canale',
        },
        dahuaDVRNVR: {
          userLabel: 'User',
          passwordLabel: 'Password',
          ipLabel: 'Ip',
          portLabel: 'Porta',
          channelLabel: 'Canale',
        },
        filter: {
          searchLabel: 'Cerca',
        },
        form: {
          nameLabel: 'Nome',
          timeoutLabel: 'Timeout (in secondi)',
          availableLabel: 'Disponibile',
          sourceImageConfigLabel: 'Origine',
        },
        listItem: {
          noImage: 'Nessuna immagine disponibile',
        },
      },
      pages: {
        list: {
          title: 'Tutte le viste a tua disposizione',
          createButtonText: 'Crea vista',
          table: {
            name: 'Descrizione',
            actions: 'Azioni',
          },
        },
        create: {
          title: 'Creazione vista',
        },
        detail: {
          title: 'Dettaglio vista',
          time: 'Ultimo aggiornamento: ',
          zoomIn: 'Zoom in',
          zoomOut: 'Zoom out',
          update: 'Aggiorna',
          updating: 'Aggiornamento...',
          notAvailable: 'Immagine al momento non disponibile',
          wait: 'Attendere... {{time}}',
        },
        edit: {
          title: 'Modifica vista',
        },
      },
    },
    cameraViewViewingPermissions: {
      components: {
        filter: {
          searchLabel: 'Cerca',
        },
        form: {
          nameLabel: 'Nome',
          userLabel: 'Utente',
          cameraViewLabel: 'Vista',
          noteLabel: 'Note',
        },
      },
      pages: {
        list: {
          tableColumn: {
            name: 'Name',
            user: 'Utente',
            cameraView: 'Vista',
            createdAt: 'Data creazione',
            actions: 'Azioni',
          },
          title: 'Permessi',
          createButtonText: 'Crea nuovo permesso',
        },
        create: {
          title: 'Creazione permesso',
        },
        edit: {
          title: 'Modifica permesso',
        },
      },
    },
    users: {
      components: {
        changePasswordForm: {
          currentPasswordLabel: 'Password corrente',
          passwordLabel: 'Nuova password',
          passwordConfirmationLabel: 'Conferma nuova password',
          submit: 'Cambia password',
        },
      },
      pages: {
        edit: {
          title: 'Modifica profilo',
        },
      },
    },
  },
};

export default translations;
