/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import CanvasDraw from 'react-canvas-draw';
import ReactCrop from 'react-image-crop';

import {
  Row,
  Col,
  Space,
  Button,
  Alert,
  Typography,
} from 'antd';

import { useTranslation } from 'react-i18next';
import Loader from '../../commons/components/Loader';
import config from '../../config';
import { AuthenticationContext } from '../../authentications/contexts/AuthenticationContext';

const getCroppedImg = (image, crop) => {
  const canvas = document.createElement('canvas');
  const { naturalWidth, naturalHeight } = image;
  const percentWidth = crop.width / 100;
  const percentHeight = crop.height / 100;
  const percentX = crop.x / 100;
  const percentY = crop.y / 100;
  canvas.width = naturalWidth * percentWidth;
  canvas.height = naturalHeight * percentHeight;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    percentX * naturalWidth,
    percentY * naturalHeight,
    percentWidth * naturalWidth,
    percentHeight * naturalHeight,
    0,
    0,
    percentWidth * naturalWidth,
    percentHeight * naturalHeight,
  );

  const base64 = canvas.toDataURL('image/jpeg');

  return {
    base64,
    width: percentWidth * naturalWidth,
    height: percentHeight * naturalHeight,
  };
};

const getMaskImg = (canvas) => {
  const destinationCanvas = document.createElement('canvas');
  destinationCanvas.height = canvas.height;
  destinationCanvas.width = canvas.width;

  const destinationContext = destinationCanvas.getContext('2d');

  destinationContext.drawImage(canvas, 0, 0);
  destinationContext.globalCompositeOperation = 'destination-over';
  destinationContext.fillStyle = 'transparent';
  destinationContext.fillRect(0, 0, destinationCanvas.width, destinationCanvas.height);

  const image = destinationCanvas.toDataURL('image/png');

  return image;
};

const CropMaskInput = (props) => {
  const {
    sourceConfig,
    value,
    onChange,
  } = props;

  const { t } = useTranslation();

  const { accessToken } = React.useContext(AuthenticationContext);

  const imageRef = React.useRef(null);
  const maskRef = React.useRef(null);

  const [error, setError] = React.useState(false);

  const [cropData, setCropData] = React.useState(value && value.cropData
    ? JSON.parse(value.cropData)
    : {
      unit: '%',
      width: 30,
      height: 30,
    });
  const [maskData, setMaskData] = React.useState(value && value.maskData ? value.maskData : null);
  const [maskImage, setMaskImage] = React.useState(value && value.maskImage
    ? value.maskImage
    : null);

  const [imageLoading, setImageLoading] = React.useState(false);

  const imageToMask = React.useMemo(() => {
    if (imageRef && imageRef.current && cropData) {
      const result = getCroppedImg(imageRef.current, cropData);
      return result;
    }
    return null;
  }, [imageRef, imageRef.current, cropData]);

  React.useEffect(() => {
    if (imageToMask && maskRef.current && value && value.maskData) {
      maskRef.current.loadSaveData(value.maskData, true);
    }
  }, [maskRef.current]);

  const maxHeight = React.useMemo(() => {
    if (imageRef.current) {
      return imageRef.current.height;
    }
    return null;
  }, [imageRef.current]);

  const bridgeSourceUrl = React.useMemo(() => {
    setError(false);
    setImageLoading(true);
    if (!sourceConfig || sourceConfig.trim() === '') {
      return null;
    }
    return `${config.bridgeImageUri}?access_token=${accessToken}&config=${sourceConfig}`;
  }, [sourceConfig]);

  const handleClearMask = React.useCallback(() => {
    if (maskRef && maskRef.current) {
      maskRef.current.clear();
      setMaskData(null);
      setMaskImage(null);
      onChange({
        maskData: null,
        maskImage: null,
        cropData: JSON.stringify(cropData),
      });
    }
  }, [maskRef.current, setMaskData, setMaskImage, cropData, onChange]);

  const handleCropImageLoaded = (img) => {
    setError(false);
    imageRef.current = img;
    imageRef.current.crossOrigin = '*';
    setImageLoading(false);
  };

  const handleCropImageError = (ads) => {
    imageRef.current = null;
    setImageLoading(false);
    setError(true);
  };

  const handleMaskChange = React.useCallback((data) => {
    const image = getMaskImg(data.canvas.drawing);
    const newMaskData = data.getSaveData();
    setMaskData(newMaskData);
    setMaskImage(image);
    onChange({
      maskData: newMaskData,
      maskImage: image,
      cropData: JSON.stringify(cropData),
    });
  }, [setMaskData, setMaskImage, cropData, onChange]);

  const handleCropChange = React.useCallback((newCrop, percentCrop) => {
    setCropData(percentCrop);
  }, [setCropData]);

  const handleCropComplete = React.useCallback(async (newCrop, percentCrop) => {
    setCropData(percentCrop);
    onChange({
      maskData,
      maskImage,
      cropData: JSON.stringify(percentCrop),
    });
  }, [setCropData, maskData, maskImage, onChange]);

  if (!bridgeSourceUrl) {
    return (
      <Row gutter={16}>
        <Col flex={1}>
          <Alert type="warning" message={t('cameraViews.components.cropMaskInput.selectOriginFirst')} />
        </Col>
      </Row>
    );
  }

  return (
    <>
      {imageLoading && (
        <Row justify="center" align="middle" style={{ height: '100%' }}>
          <Loader />
        </Row>
      )}
      {error && (
        <Alert type="error" message={t('cameraViews.components.cropMaskInput.loadingError')} />
      )}
      {!error && (
        <Row gutter={16}>
          <Col span={24}>
            <Typography.Text style={{ display: 'block' }}>{t('cameraViews.components.cropMaskInput.cut')}</Typography.Text>
            <ReactCrop
              src={bridgeSourceUrl}
              crop={cropData}
              onChange={handleCropChange}
              onComplete={handleCropComplete}
              onImageLoaded={handleCropImageLoaded}
              onImageError={handleCropImageError}
            />
          </Col>
          <Col span={24}>
            {maxHeight && imageToMask && (
              <Space size="large" direction="vertical" style={{ width: '100%' }}>
                <Row>
                  <Col flex={1}>
                    <Typography.Text style={{ display: 'block' }}>{t('cameraViews.components.cropMaskInput.privacy')}</Typography.Text>
                    <div style={{ overflow: 'scroll', height: maxHeight, position: 'relative' }}>
                      <CanvasDraw
                        canvasWidth={imageToMask.width}
                        canvasHeight={imageToMask.height}
                        brushRadius={24}
                        ref={(el) => {
                          maskRef.current = el;
                        }}
                        imgSrc={imageToMask.base64}
                        onChange={handleMaskChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row align="middle" justify="center">
                  <Col>
                    <Button
                      onClick={handleClearMask}
                      type="primary"
                    >
                      {t('cameraViews.components.cropMaskInput.clearButton')}
                    </Button>
                  </Col>
                </Row>
              </Space>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

const propTypes = {
  sourceConfig: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape(({
    cropData: PropTypes.string.isRequired,
    maskImage: PropTypes.string,
    maskData: PropTypes.string,
  })),
};

const defaultProps = {
  value: null,
  sourceConfig: null,
};

CropMaskInput.propTypes = propTypes;
CropMaskInput.defaultProps = defaultProps;

export default CropMaskInput;
