import { gql } from '@apollo/client';

export const CAMERA_VIEW_LIST_FRAGMENT = gql`
  fragment CameraViewListFragment on CameraView {
    id
    name
    available
    lastImageDate
    imageUrl
    timeout
    iAmOwner
  }
`;

export const CAMERA_VIEW_EDIT_FRAGMENT = gql`
  fragment CameraViewEditFragment on CameraView {
    id
    cropData
    maskData
    sourceImageConfig
    ...CameraViewListFragment
  }
  ${CAMERA_VIEW_LIST_FRAGMENT}
`;

export const CAMERA_VIEW_SHOW_FRAGMENT = gql`
  fragment CameraViewShowFragment on CameraView {
    id
    ...CameraViewListFragment
  }
  ${CAMERA_VIEW_LIST_FRAGMENT}
`;

export const CAMERA_VIEW_EDIT_QUERY = gql`
query cameraViewEditQuery($id: ID!) {
  viewer {
    id
    cameraView(id: $id) {
      ...CameraViewEditFragment
    }
  }
}
${CAMERA_VIEW_EDIT_FRAGMENT}
`;

export const CAMERA_VIEWS_QUERY = gql`
query cameraViewsQuery($afterCursor: String, $pageSize: Int!, $filter: CameraViewFilter) {
  viewer {
    id
    cameraViews(first: $pageSize, after: $afterCursor, filter: $filter) {
      edges {
        node {
          id
          ...CameraViewListFragment
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
}
${CAMERA_VIEW_LIST_FRAGMENT}
`;

export const CAMERA_VIEW_SHOW_QUERY = gql`
query cameraViewQuery($id: ID!) {
  viewer {
    id
    cameraView(id: $id) {
      ...CameraViewShowFragment
    }
  }
}
${CAMERA_VIEW_SHOW_FRAGMENT}
`;

export const CAMERA_VIEW_CREATE_MUTATION = gql`
  mutation CameraViewCreateMutation($input: CameraViewCreateInput!) {
    cameraViewCreate(input: $input) {
      cameraView {
        id
        ...CameraViewEditFragment
      }
    }
  }
  ${CAMERA_VIEW_EDIT_FRAGMENT}
`;

export const CAMERA_VIEW_UPDATE_MUTATION = gql`
  mutation CameraViewUpdateMutation($input: CameraViewUpdateInput!) {
    cameraViewUpdate(input: $input) {
      cameraView {
        id
        ...CameraViewEditFragment
      }
    }
  }
  ${CAMERA_VIEW_EDIT_FRAGMENT}
`;
