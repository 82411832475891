import React from 'react';
import PropTypes from 'prop-types';
import { Alert, List } from 'antd';
import { useTranslation } from 'react-i18next';

const ErrorAlert = (props) => {
  const { t } = useTranslation();
  const { errors } = props;

  const renderErrors = () => (
    <List
      size="small"
      dataSource={errors}
      renderItem={(item) => <List.Item>{item}</List.Item>}
    />
  );

  return (
    <Alert
      message={t('common.someErrorOccurred')}
      description={renderErrors()}
      type="error"
      showIcon
      closable
    />
  );
};

const propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ErrorAlert.propTypes = propTypes;

export default ErrorAlert;
