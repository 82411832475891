import React from 'react';
import { Row } from 'antd';
import { useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';

import Loader from '../../commons/components/Loader';

import { AuthenticationContext } from '../../authentications/contexts/AuthenticationContext';
import { USER_CONFIRM_MUTATION } from '../queries';

const UserConfirmPage = () => {
  const { token: confirmationToken } = useParams();
  const navigate = useNavigate();
  const { login } = React.useContext(AuthenticationContext);

  const [
    userConfirmMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(USER_CONFIRM_MUTATION);

  React.useEffect(() => {
    const runMutation = async () => {
      try {
        const result = await userConfirmMutationAction({
          variables: {
            input: {
              token: confirmationToken,
            },
          },
        });
        if (result.data && result.data.userConfirm) {
          login(result.data.userConfirm.token);
        }
        navigate('/', { replace: true });
      }
      catch (e) { } // eslint-disable-line
    };

    if (confirmationToken) {
      runMutation();
    }
  }, [confirmationToken, navigate, login, userConfirmMutationAction]);

  if (loading) {
    return (
      <Row type="flex" justify="center" align="middle" style={{ height: '100vh' }}>
        <Loader />
      </Row>
    );
  }

  if (error) {
    return (
      <Row type="flex" justify="center" align="middle" style={{ height: '100vh' }}>
        <Loader />
      </Row>
    );
  }

  return null;
};

export default UserConfirmPage;
