/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';

import {
  Row,
  Col,
  Form,
  Space,
  Input,
  Button,
} from 'antd';
import { useTranslation } from 'react-i18next';

const HikvisionDVRForm = (props) => {
  const {
    config,
    onSubmit,
  } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: config });

  const { t } = useTranslation();

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Row gutter={16}>
          <Col span={12}>
            <Controller
              name="user"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('cameraViews.components.hikvisionDVR.userLabel')}
                  validateStatus={errors != null && errors.user != null ? 'error' : null}
                  help={errors && errors.user && errors.user.message}
                >
                  <Input
                    value={field.value}
                    onChange={field.onChange}
                    size="large"
                  />
                </Form.Item>
              )}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('cameraViews.components.hikvisionDVR.passwordLabel')}
                  validateStatus={errors != null && errors.password != null ? 'error' : null}
                  help={errors && errors.password && errors.password.message}
                >
                  <Input
                    value={field.value}
                    onChange={field.onChange}
                    size="large"
                  />
                </Form.Item>
              )}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Controller
              name="ip"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('cameraViews.components.hikvisionDVR.ipLabel')}
                  validateStatus={errors != null && errors.ip != null ? 'error' : null}
                  help={errors && errors.ip && errors.ip.message}
                >
                  <Input
                    value={field.value}
                    onChange={field.onChange}
                    size="large"
                  />
                </Form.Item>
              )}
              rules={{
                required: t('validations.required'),
              }}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="port"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('cameraViews.components.hikvisionDVR.portLabel')}
                  validateStatus={errors != null && errors.port != null ? 'error' : null}
                  help={errors && errors.port && errors.port.message}
                >
                  <Input
                    value={field.value}
                    onChange={field.onChange}
                    size="large"
                  />
                </Form.Item>
              )}
              rules={{
                required: t('validations.required'),
              }}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col flex={1}>
            <Controller
              name="channel"
              control={control}
              render={({ field }) => (
                <Form.Item
                  label={t('cameraViews.components.hikvisionDVR.channelLabel')}
                  validateStatus={errors != null && errors.port != null ? 'error' : null}
                  help={errors && errors.channel && errors.channel.message}
                >
                  <Input
                    value={field.value}
                    onChange={field.onChange}
                    size="large"
                  />
                </Form.Item>
              )}
              rules={{
                required: t('validations.required'),
              }}
            />
          </Col>
        </Row>
        <Button
          block
          type="primary"
          htmlType="submit"
        >
          {t('common.save')}
        </Button>
      </Space>
    </Form>
  );
};

const propTypes = {
  config: PropTypes.any,
  onSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  config: null,
};

HikvisionDVRForm.propTypes = propTypes;
HikvisionDVRForm.defaultProps = defaultProps;

export default HikvisionDVRForm;
