import React from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
} from 'antd';

import { useLazyQuery } from '@apollo/client';
import { CAMERA_VIEWS_QUERY } from '../queries';

import DebounceSelect from '../../commons/components/DebounceSelect';

const CameraViewInput = (props) => {
  const {
    value,
    onChange,
    onlyMine,
    allowClear,
  } = props;

  const [getCameraViews, { loading, data }] = useLazyQuery(CAMERA_VIEWS_QUERY);

  const options = React.useMemo(() => {
    if (data && !loading) {
      const { viewer } = data;
      const { cameraViews: cameraViewConnection } = viewer;
      const cameraViews = cameraViewConnection.edges.map((e) => e.node);

      return cameraViews.map((cv) => ({
        label: cv.name,
        value: cv.id,
      }));
    }
    return [];
  }, [data, loading]);

  const fetch = async (term) => {
    const variables = {
      pageSize: 50,
      filter: {
        search: term,
        onlyMine,
      },
    };
    getCameraViews({
      variables,
    });
  };

  return (
    <Row gutter={16}>
      <Col flex={1}>
        <DebounceSelect
          allowClear={allowClear}
          mode="single"
          size="large"
          value={value}
          fetchOptions={fetch}
          options={options}
          fetching={loading}
          debounceTimeout={500}
          onChange={(newValue) => {
            onChange(newValue);
          }}
          style={{
            width: '100%',
          }}
        />
      </Col>
    </Row>
  );
};

const propTypes = {
  value: PropTypes.shape({
    label: PropTypes.string.isRequried,
    value: PropTypes.string.isRequried,
  }),
  onChange: PropTypes.func.isRequired,
  onlyMine: PropTypes.bool,
  allowClear: PropTypes.bool,
};

const defaultProps = {
  value: null,
  onlyMine: false,
  allowClear: false,
};

CameraViewInput.propTypes = propTypes;
CameraViewInput.defaultProps = defaultProps;

export default CameraViewInput;
