/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ErrorAlert from './ErrorAlert';
import { extractGraphQLErrors } from '../../utils/graphql';

const GraphQLErrorAlert = (props) => {
  const { error } = props;
  const { t } = useTranslation();

  const parsedErrors = extractGraphQLErrors(error, t);

  return (
    <ErrorAlert
      errors={parsedErrors}
    />
  );
};

const propTypes = {
  error: PropTypes.any.isRequired,
};

GraphQLErrorAlert.propTypes = propTypes;

export default GraphQLErrorAlert;
